import Impact from '../components/impact'
import get from 'lodash/get'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import React from 'react'

class NewsIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const posts = get(this, 'props.data.allContentfulInTheNews.edges')

    return (
      <Layout location={this.props.location}>
        <div style={{ background: '#fff' }}>
          <Helmet title={siteTitle} />
          <div className="wrapper">
            <h2 className="works-headline">In the News</h2>
            <ul className="news-list">
              {posts.map(({ node }) => {
                return (
                  <li key={node.title}>
                    <Impact news={node} />
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </Layout>
    )
  }
}

export default NewsIndex

export const pageQuery = graphql`
  query NewsIndexQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulInTheNews(sort: {fields: updatedAt, order: DESC}) {
      edges {
        node {
          title
          description {
            childMarkdownRemark {
              html
            }
          }
          link
          newsImage {
            fluid(maxHeight: 300, quality: 100) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`
