import { Link } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import styles from './impact.module.css'

export default ({ news }) => (
  <div className={styles.previewBox}>
    {news.link && <a href={news.link} style={{ textDecoration: 'none' }}>
        <div className={styles.previewContainer}>
            <Img
                className={styles.previewImage}
                fluid={news.newsImage.fluid}
                object-fit="contain"
            />
            <div className={styles.textContainer}>
                <div className={styles.headline}><h2>{news.title}</h2></div>
                <div
                    className={styles.details}
                    dangerouslySetInnerHTML={{
                        __html: news.description.childMarkdownRemark.html,
                    }}>
                </div>
                <div className={styles.readMore}>Read More
                    <span className={styles.readMoreArrow}>
                        arrow_right_alt
                    </span>
                </div>
            </div>
        </div>
    </a>}
    {!news.link && <Link style={{ textDecoration: 'none' }} to={`/news/${news.title}`}>
        <div className={styles.previewContainer}>
            <Img
                className={styles.previewImage}
                fluid={news.newsImage.fluid}
                object-fit="contain"
            />
            <div className={styles.textContainer}>
                <div className={styles.headline}><h2>{news.title}</h2></div>
                <div
                    className={styles.details}
                    dangerouslySetInnerHTML={{
                        __html: news.description.childMarkdownRemark.html,
                    }}>
                </div>
                <div className={styles.readMore}>Read More
                    <span className={styles.readMoreArrow}>
                        arrow_right_alt
                    </span>
                </div>
            </div>
        </div>
    </Link>}
  </div>
)
